/* About Section */
.about {
    padding: 4rem 2rem;
    background: var(--color-light);
}

.about-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
}

.about-image img {
    width: 100%;
    height: auto;
}

.about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about h2 {
    font-family: var(--font-sans);
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    line-height: 1.1;
    border-left: var(--border-red);
    padding-left: 1rem;
}

.about p {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
}

.highlight-box {
    background: white;
    padding: 1.5rem;
    margin: 2rem 0;
    border-left: var(--border-red);
}

.highlight-box p {
    font-family: var(--font-sans);
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.3;
    margin: 0;
}