.hero {
    background: url('/assets/img/building-future.webp');
    color: white;
    padding: 4rem 2rem;
    position: relative;
    background-size: cover;
    background-position: bottom;
    height: calc(100vh - 93px);
}

.hero-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    align-items: center;
}

.hero h1 {
    font-family: var(--font-sans);
    font-size: 3.5rem;
    line-height: 1.1;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    border-left: var(--border-red);
    padding-left: 1rem;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    font-family: var(--font-serif);
    font-weight: 400;
}

.cta-button {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    background: var(--color-primary);
    color: white;
    text-decoration: none;
    font-family: var(--font-sans);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    transition: all 0.3s ease;
}

.cta-button:hover {
    background: white;
    color: var(--color-primary);
}

.hero-image img {
    width: 100%;
    height: auto;
}

@media (max-width: 500px) {
    .hero h1 {
        font-family: var(--font-sans);
        font-size: 2.4rem;
        line-height: 1.1;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1rem;
        border-left: var(--border-red);
        padding-left: 1rem;
    }
}