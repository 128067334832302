/* Contact Section */
.contact {
    padding: 4rem 2rem;
    background: var(--color-dark);
    color: white;
}

.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.contact h2 {
    font-family: var(--font-sans);
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}

.contact p {
    max-width: 600px;
    margin: 0 auto 2rem;
    font-size: 1.1rem;
}

.contact-email {
    color: white;
    text-decoration: none;
    padding-bottom: 2px;
}

.contact-email:hover {
    color: var(--color-primary);
}
.social-link {
    color: var(--color-light);
    text-decoration: none;
    font-family: var(--font-sans);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.8rem;
}

.social-link:hover {
    color: var(--color-primary);
}