// Variables
$primary-color: #2563eb;
$primary-hover: #1d4ed8;
$text-dark: #111827;
$text-light: #4b5563;
$bg-light: #ffffff;
$bg-dark: #000000;
$border-radius: 0.75rem;
$transition-speed: 0.2s;

// Mixins
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

// @mixin hover-scale {
//     transition: transform $transition-speed ease-in-out;

//     &:hover {
//         transform: scale(1.02);
//     }
// }

// BentoGallery Component Styles
.bento-gallery {
    width: 100%;
    max-width: 72rem;
    margin: 0 auto;
    padding: 1rem;

    &__title {
        font-size: 1.875rem;
        font-weight: 700;
        margin-bottom: 2rem;
        color: $text-dark;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        position: relative;

        @media (max-width: 640px) {
            grid-template-columns: 1fr;
        }
    }

    // Project Card
    &__card {
        background-color: $bg-light;
        overflow: hidden;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
        cursor: pointer;
        // @include hover-scale;

        &--featured {
            grid-row: span 1;

            @media (max-width: 640px) {
                grid-row: auto;
            }
        }

        &--wide {
            grid-column: span 1;

            @media (max-width: 640px) {
                grid-column: auto;
            }
        }

        &-image {
            width: 100%;
            height: 12rem;
            object-fit: cover;
        }

        &-content {
            padding: 1rem;
        }

        &-category {
            font-size: 0.875rem;
            color: var(--color-primary);
            font-weight: 500;
        }

        &-title {
            font-size: 1.125rem;
            font-weight: 600;
            margin: 0.25rem 0 0.5rem;
            color: $text-dark;
            font-family: var(--font-sans);
        }

        &-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
        }
    }

    // Tag Component
    &__tag {
        padding: 0.25rem 0.75rem;
        background-color: #f3f4f6;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        color: $text-light;
        font-family: var(--font-sans);
        text-transform: uppercase;

        &:hover {
            background-color: #e5e7eb;
        }
    }

    // Modal
    &__modal {
        position: fixed;
        inset: 0;
        background-color: rgba($bg-dark, 0.8);
        @include flex-center;
        padding: 1rem;
        z-index: 50;

        &-content {
            background-color: $bg-light;
            border-radius: $border-radius;
            overflow: hidden;
            max-width: 48rem;
            width: 100%;
            position: relative;
        }

        &-image {
            width: 100%;
        }

        &-body {
            padding: 1.5rem;
        }

        &-category {
            font-size: 0.875rem;
            color: $primary-color;
            font-weight: 500;
        }

        &-title {
            font-size: 1.5rem;
            font-weight: 700;
            font-family: var(--font-sans);
            margin: 0.5rem 0 1rem;
            color: $text-dark;
        }

        &-description {
            font-size: 1.125rem;
            color: $text-light;
            margin-bottom: 1rem;
        }

        &-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            margin-bottom: 1rem;
        }

        &-link {
            display: inline-block;
            padding: 0.5rem 1rem;
            background-color: $primary-color;
            color: $bg-light;
            border-radius: 0.5rem;
            text-decoration: none;
            transition: background-color $transition-speed ease-in-out;

            &:hover {
                background-color: $primary-hover;
            }
        }

        &-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background-color: #000;
            color: #fff;
            padding: 0.5rem;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;
            border: 0px;
            border-radius: 25px;
            &:hover {
                background-color: rgba($bg-dark, 0.7);
            }

            svg {
                width: 1.5rem;
                height: 1.5rem;
                top: 2px;
                position: relative;
            }
        }
    }

    // Animation Classes
    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active {
        opacity: 1;
        transition: opacity $transition-speed ease-in-out;
    }

    .fade-exit {
        opacity: 1;
    }

    .fade-exit-active {
        opacity: 0;
        transition: opacity $transition-speed ease-in-out;
    }

    .scale-enter {
        transform: scale(0.5);
        opacity: 0;
    }

    .scale-enter-active {
        transform: scale(1);
        opacity: 1;
        transition: all $transition-speed ease-in-out;
    }

    .scale-exit {
        transform: scale(1);
        opacity: 1;
    }

    .scale-exit-active {
        transform: scale(0.5);
        opacity: 0;
        transition: all $transition-speed ease-in-out;
    }
}

// Dark Mode Styles
@media (prefers-color-scheme: dark) {
    .bento-gallery {
        &__card {
            background-color: #1f2937;

            &-category {
                color: #60a5fa;
            }

            &-title {
                color: #f3f4f6;
            }
        }

        &__tag {
            background-color: #374151;
            color: #d1d5db;
            border-radius: 0.25rem;

            &:hover {
                background-color: #4b5563;
            }
        }

        &__modal {
            &-content {
                background-color: #1f2937;
            }

            &-category {
                color: #60a5fa;
            }

            &-title {
                color: #f3f4f6;
            }

            &-description {
                color: #d1d5db;
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .bento-gallery__modal-close {
        background-color: #fff;
        color: #000;
    }
    .bento-gallery__modal-content {
        background-color: #ffffff;
        border-radius: 0.75rem;
        overflow: hidden;
        max-width: 48rem;
    }
    .bento-gallery__modal-image {
        display: none;
    }
}