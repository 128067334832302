.timeline {
    background-color: white;
    padding: 4rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.timeline-container {
    max-width: 1200px;
    margin: 0 auto;
}

.timeline-entries {
    position: relative;
    margin-top: 3rem;
}
.timeline-entries {
    position: relative;
    margin-top: 3rem;

    &:before {
        content: '';
        position: absolute;
        left: 13px;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: var(--color-light);
    }

    &:after {
        content: '';
        position: absolute;
        left: 13px;
        top: 0;
        width: 4px;
        height: var(--filled-height, 0px);
        background: repeating-linear-gradient(to bottom,
                var(--color-primary),
                var(--color-primary) 6px,
                transparent 6px,
                transparent 12px);
        transition: height 1s ease-out;
    }
}

.timeline-entry, .timeline-entry-first {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 1rem;
    margin-bottom: 3rem;
    position: relative;
}

.timeline-entry:last-child {
    margin-bottom: 0;
}

.timeline-entry:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 15px;
    width: 20px;
    height: 20px;
    background-color: var(--color-primary);
    border-radius: 50%;
    z-index: 1;
}

.timeline-date {
    text-align: right;
    padding-top: 0.5rem;
}

.timeline-date .year {
    font-family: var(--font-sans);
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--color-dark);
    display: block;
}

.timeline-date .range {
    font-family: var(--font-sans);
    font-size: 1rem;
    color: var(--color-mid);
}

.timeline-content {
    padding-top: 0.5rem;
    position: relative;
}

.timeline-title {
    font-family: var(--font-sans);
    font-weight: 700;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    color: var(--color-dark);
}

.timeline-company {
    font-family: var(--font-sans);
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color-primary);
    margin-bottom: 1rem;
}

.timeline-description {
    font-family: var(--font-serif);
    font-size: 1rem;
    color: var(--color-dark);
    line-height: 1.6;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .timeline-entry, .timeline-entry-first {
        grid-template-columns: 1fr;
        gap: 0.5rem;
        padding-left: 2rem;
    }

    .timeline-entries:before {
        left: 13px;
    }

    .timeline-entry:before {
        left: 5px;
    }

    .timeline-date {
        text-align: left;
    }

    .timeline-date .year,
    .timeline-date .range {
        display: inline;
    }
}