.services {
    

    .services-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 2rem;
        margin-top: 2rem;
    }

    .service-box {
        background-color: #f2f2f2;
        border: 2px dotted #d71a28;
        padding: 1.5rem;
        .icon {
            color: #d71a28;
        }

        h3 {
            font-family: var(--font-sans);
            font-size: 1.3rem;
            font-weight: 700;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }

        p {
            font-family: var(--font-serif);
            font-size: 1rem;
            color: #333;
        }
    }
}