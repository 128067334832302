 @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Merriweather:ital,wght@0,400;0,700;1,400&display=swap');
:root {
    --font-sans: 'Roboto Condensed', Arial, sans-serif;
    --font-serif: 'Merriweather', Georgia, serif;
    --color-primary: #e90606;
    --color-dark: #121212;
    --color-light: #f4f4f4;
    --color-mid: #888;
    --color-category: #eee;
    --border-red: 4px solid var(--color-primary);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--font-serif);
    line-height: 1.5;
    color: var(--color-dark);
    background-color: white;
    padding: 0;
    margin: 0;
}

.section-title {
    font-family: var(--font-sans);
    font-size: 2.5rem;
    text-transform: uppercase;
    color: var(--color-dark);
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.section-title:before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    background: var(--color-primary);
    margin-right: 1rem;
}
/* Responsive Adjustments */
@media (max-width: 768px) {

    .hero-container,
    .about-container {
        grid-template-columns: 1fr;
    }

    .header-container {
        flex-direction: column;
        gap: 1rem;
    }

    .section-title {
        font-size: 2rem;
    }

    .footer-container {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }
}
