/* Skills Section */
.skills {
    padding: 4rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.skills-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.skill-category {
    border-top: 1px solid var(--color-mid);
    padding: 1rem;
    background-color: var(--color-category)
}

.skill-category h3 {
    font-family: var(--font-sans);
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.skill-list {
    list-style-type: none;
}

.skill-list li {
    margin-bottom: 0.5rem;
    font-family: var(--font-serif);
    font-size: 0.9rem;
}

.skill-list li:before {
    content: '- ';
}