/* Header */
header {
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
}

.header-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em;
    position: relative;
}

.logo {
    font-family: var(--font-sans);
    font-weight: 700;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: var(--color-primary);
    letter-spacing: -0.03em;
}

/* Hamburger Icon */
.hamburger {
    display: none;
    width: 30px;
    height: 20px;
    flex-direction: column;
    justify-content: space-between;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 4;
    position: absolute;
    top: 1.3rem;
    right: 2rem;

    span {
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-dark);
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    &.open span:nth-child(1) {
        transform: translateY(8px) rotate(43deg);
    }

    &.open span:nth-child(2) {
        opacity: 0;
    }

    &.open span:nth-child(3) {
        transform: translateY(-11px) rotate(-45deg);
    }
}

/* Navigation Styles */
nav ul {
    list-style-type: none;
    display: flex;
    gap: 2rem;
}

nav a {
    text-decoration: none;
    color: var(--color-dark);
    font-family: var(--font-sans);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.05em;

    &:hover {
        color: var(--color-primary);
    }
}

/* Responsive Styles */
@media (max-width: 500px) {
    .hamburger {
        display: flex;
    }

    nav {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        background-color: white;
        transform: translateY(-100%);
        transition: transform 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;

        &.open {
            transform: translateY(0);
        }

        ul {
            flex-direction: column;
            gap: 1.5rem;
            padding: 0;
            text-align: center;
            width: 100%;
        }

        li {
            width: 100%;
        }

        a {
            display: block;
            width: 100%;
            padding: 1rem 0;
            font-size: 1.2rem;
        }
    }
}